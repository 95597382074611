import React, { useState } from 'react';
import { registerCompany, registerUser } from '../api/mutations';
import CompanySignup from '../CompanySignup';
import Signup from '../Signup';
import { Typography } from '@mui/material';

import { useSetRecoilState } from 'recoil';
import routes from '../../../routes'
import { userAuth } from '../api/state';
import { login, loginSet } from '../api/query';

const CombinedSignup = ({ history, toggleScreen }) => {
    const defaultUserInfo = {
        fname: null,
        lname: null,
        country: null,
        phone: null,
        email: null,
        password: null,
    }
    const defaultCompanyInfo = {
        bname: null,
        baddress: null,
        city: null,
        state: null,
        zip: null,
        bphone: null,
    }

    const [view, setView] = useState(<Signup errorMessage={""} history={history} submit={userUpdate} toggleScreen={toggleScreen} />)
    const setUserAuth = useSetRecoilState(userAuth)
    const [generalError, setErrorExists] = useState("")

    function userUpdate(userData) {
        setView(<CompanySignup history={history} userInfo={userData} submit={registration} />)
    }

    async function registration(userData, companyData, history) {

        try {
            const userResponse = await registerUser(
                userData.fname,
                userData.lname,
                userData.country,
                userData.phone,
                userData.email,
                userData.password)
            const userDataUpdate = {
                fname: userResponse.fname,
                lname: userResponse.lname,
                email: userResponse.email,
                country: userResponse.country,
            }

            if (userDataUpdate.email) {
                const company = await registerCompany(userData.email, companyData.bname, companyData.baddress, companyData.zip, companyData.bphone)
                const userUpdate = {
                    ...userDataUpdate,
                    company
                }
                setUserAuth({
                    isAuthenticated: true,
                    user: userUpdate
                })
                loginSet(userDataUpdate.token, userUpdate)
                history.push(routes.user.welcome)
            } else {
                throw "Returned empty response. Contact customer support."
            }
        } catch (error) {
            setErrorExists(error)
            setView(<Signup errorMessage={error} history={history} submit={userUpdate} toggleScreen={toggleScreen} />)
        }
    }

    return (
        <div>
            {view}
        </div>
    )
}

export default CombinedSignup