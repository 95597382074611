import { Container, Drawer, Box, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material"
import React, { useState } from "react"
import { userAuth } from "../../user/api/state"
import { AccountBox, AddBoxOutlined, Home, InsertChart } from "@mui/icons-material"
import { useRecoilValue } from "recoil"
import VineyardsOverview from "../VineyardsOverview"
import AddBlockMap from "../AddBlockMap"
import BlockData from "../BlockData"
import BlockSubscriptions from "../BlockSubscriptions"

const Console = () => {

    const auth = useRecoilValue(userAuth)

    const drawerWidth = 275

    const [consoleView, setView] = useState(<AddBlockMap />)

    const getIcon = (index) => {
        let icon
        switch (index) {
            case 1:
                icon = <AddBoxOutlined />
                break
            case 2:
                icon = <AccountBox />
                break
            case 3:
                icon = <InsertChart />
                break
            default:
                icon = <Home />
        }
        return icon
    }

    const menuClicked = (event) => {
        let view
        let innerText = event.target.innerText
        switch (innerText) {
            case "Add Block Map":
                view = <AddBlockMap />
                break
            case "View Block Data":
                view = <BlockData />
                break
            case "Manage Subscriptions":
                view = <BlockSubscriptions />
                break
            default:
                view = <VineyardsOverview />
        }
        setView(view)
    }
    return (
        <Container maxWidth={false} sx={{
            marginBottom: "10px", width: "100%", minHeight: "100%", height: "90vh", display: "flex", alignItems: "stretch", justifyItems: "stretch", alignSelf: "stretch"
        }}>
            <Box sx={{ display: 'flex', alignContent: "stretch", alignItems: "stretch", justifyItems: "stretch", width: "100%", height: "100%" }
            } >
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    <Box sx={{ overflow: "auto", marginTop: "100px" }}>
                        <List>
                            {["Home", "Add Block Map", "Manage Subscriptions", "View Block Data"].map((text, index) => (
                                <ListItem button id={index} key={text} onClick={menuClicked}>
                                    <ListItemIcon>
                                        {getIcon(index)}
                                    </ListItemIcon>
                                    <ListItemText primary={text} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "auto",
                        display: "flex",
                        flexDirection: "column",
                        flex: '1 1 auto',
                        position: "static",
                        maxHeight: "100%",
                        marginBottom: 0,
                        alignItems: "stretch"
                    }}
                >
                    <Paper
                        sx={{
                            marginTop: "25px",
                            width: "100%",
                            minHeight: "100%",
                            minWidth: "300px",
                            maxHeight: "100%",
                            maxWidth: "100%",
                            display: "flex",
                            position: "static",
                            flexDirection: "row",
                            alignItems: "stretch",
                            flex: '1 1 auto',
                            height: "auto",
                            marginBottom: 0,
                            justifyItems: "stretch",
                            justifyContent: "center",
                        }}
                    >
                        {consoleView}
                    </Paper>
                </Box>
            </Box >
        </Container >
    )
}

export default Console