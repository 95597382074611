import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

const tiers = [
    {
        title: 'Free',
        price: '0',
        description: [
            'In field app testing',
            'Help center access',
            'Email support',
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
        href: "/login"
    },
    {
        title: 'Early Adopter',
        subheader: 'Sign up by Nov 19th',
        price: '15',
        description: [
            'Map data and platform',
            'Summary block statistics',
            'In field app testing',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Get started',
        buttonVariant: 'contained',
        href: "/login"
    },
    {
        title: 'Regular',
        price: '20',
        description: [
            'Map data and platform',
            'Summary block statistics',
            'In field app testing',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'After Nov. 19th, 2022',
        buttonVariant: 'diabled',
        href: ""
    },
    {
        title: 'Development Program',
        price: 'Custom',
        description: [
            'Early access to new features',
            'Price disouncts',
            'Map data and platform',
            'Summary block statistics',
            'In field app testing',
            'Help center access',
            'Priority email support',
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
        href: "mailto:developerprogram@terroirai.com"
    },
];

const Pricing = () => {
    return (
        <React.Fragment>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    color="text.primary"
                    gutterBottom
                    sx={{ fontSize: { xs: "25px", sm: "25px", md: "28px" }, fontWeight: { xs: "bold", sm: "normal" } }}
                >
                    Pricing
                </Typography>
                <Typography sx={{ fontSize: { xs: "16px", sm: "18px", md: "23px" } }} variant="h5" align="center" color="text.secondary" component="p">
                    Total platform access is available in the following packages.
                    Users who sign up by November 1st, 2022 receive a 25% discount.
                    We also offer a development program for select customers who work with us
                    to calibrate and improve our products.
                </Typography>
            </Container>
            {/* End hero unit */}
            <Container maxWidth="100%" component="main">
                <Grid container spacing={5} alignItems="flex-end">
                    {tiers.map((tier) => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid
                            item
                            key={tier.title}
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                        >
                            <Card>
                                <CardHeader
                                    title={tier.title}
                                    subheader={tier.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    action={tier.title === 'Early Adopter' ? <StarIcon /> : null}
                                    subheaderTypographyProps={{
                                        align: 'center',
                                    }}
                                    sx={{
                                        backgroundColor: (theme) =>
                                            theme.palette.mode === 'light'
                                                ? theme.palette.grey[200]
                                                : theme.palette.grey[700],
                                    }}
                                />
                                <CardContent sx={{ height: "250px" }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            mb: 2,
                                        }}
                                    >   {tier.title !== "Development Program" &&
                                        <Grid sx={{ display: "flex" }} container alignItems="center" justifyContent="center">
                                            <Grid item xs={6} alignItems="flex-end">
                                                <Typography sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "5px" }} component="h2" variant="h3" color="text.primary">
                                                    ${tier.price}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6} alignItems="flex-start">
                                                <Typography variant="h6" color="text.secondary">
                                                    /acre
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        }
                                    </Box>
                                    <ul>
                                        {tier.description.map((line) => (
                                            <Typography
                                                component="li"
                                                variant="subtitle1"
                                                align="center"
                                                key={line}
                                            >
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions>
                                    <Button href={tier.href} fullWidth variant={tier.buttonVariant}>
                                        {tier.buttonText}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    )
}

export default Pricing