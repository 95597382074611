import { Container, Paper, Typography, Grid, Link, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Pricing from '../../common/Pricing';
import { styled } from "@mui/material/styles"
import ChristmasButton from '../../common/ChristmasButton';

const Home = ({ history }) => {
    return (
        <div>
            <div style={{ backgroundColor: "black", width: "100vw" }}>
                <Container sx={{ width: "100vw", backgroundColor: "black" }}>
                    <Paper
                        sx={{
                            position: "relative",
                            backgroundColor: "black",
                            color: "white",
                            height: { sm: "800px", md: "700px" },
                            width: "100%"
                        }}
                    >
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item md={6}>
                                <Box
                                    sx={{
                                        position: "relative",
                                        p: { xs: 3, md: 12 },
                                        pr: { md: 0 },
                                    }}>
                                    <Typography component="h1" variant="h3" gutterBottom sx={{ fontSize: { xs: "28px", sm: "32px", md: "42px" } }}>
                                        The Field Fitness Tracker
                                    </Typography>
                                    <Typography variant="h5" paragraph sx={{ fontSize: { xs: "18px", sm: "25px", md: "28px" } }}>
                                        Providing real-time insights to help you manage your farm and meet your harvest goals
                                    </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: "bold", paddingTop: "25px" }} paragraph>
                                        Our real-time red leaf virus mapping app is available as an early release now!
                                    </Typography>
                                    <ChristmasButton variant="contained" href="/login" sx={{ fontWeight: "bold" }}>Sign up</ChristmasButton>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                                <Box sx={{
                                    display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center", width: "100% "
                                }}>
                                    < img
                                        src={process.env.PUBLIC_URL + '/img/iphone_mockup.png'}
                                        alt="App Screenshot"
                                        height="512"
                                        width="272"
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container >
            </div>
            <div>
                <Container sx={{ width: "100vw" }}>
                    <Paper
                        elevation={0}
                        sx={{
                            position: "relative",
                            color: "black",
                            width: "100%"
                        }}
                    >
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item md={12}>
                                <Box
                                    sx={{
                                        position: "relative",
                                        pt: { xs: 3, md: 12 },
                                        pl: { xs: 3, md: 12 },
                                        pr: { md: 0 },
                                    }}>
                                    <Typography align="center" component="h2" variant="h3" gutterBottom sx={{ fontSize: { xs: "25px", sm: "25px", md: "28px" }, paddingBottom: "15px" }}>
                                        Optimize Field Management With Real-Time Data and Insights
                                    </Typography>
                                    <Typography variant="h5" color="text.secondary" paragraph sx={{ fontSize: { xs: "16px", sm: "18px", md: "23px" } }}>
                                        We translate data collected in the field into <span style={{ color: "black", fontWeight: "bold" }}>maps, summary statistics, and forecasts</span> to help you make decisions when it counts.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "flex-start" }}>
                                <Box sx={{
                                    display: "flex", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "center", width: "100% "
                                }}>
                                    < img
                                        src={process.env.PUBLIC_URL + '/img/map.png'}
                                        alt="App Screenshot"
                                        width="80%"
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{
                                    display: "flex", alignItems: "flex-start", alignContent: "flex-start", justifyContent: "center", width: "100% "
                                }}>
                                    <Typography sx={{ fontSize: "12px" }}>Cluster count estimates during 2021 season.</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div >
            <div>
                <Pricing />
            </div>
        </div>
    )
}

export default Home