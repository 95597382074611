import axios from 'axios';

export async function login(email, password) {
    try {
        const response = await axios.post("https://customergraph-gd3l4zjdya-uw.a.run.app/graphql", {
            query: `mutation SignIn($email: String!, $password: String!) {
                signIn(email: $email, password: $password){email, fname, lname, token, country}
            }`,
            variables: { email: email, password: password },
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        console.log(response)
        const responseData = response.data.data
        if (responseData === null) {
            console.log("Invalid Credentials")
            return { error: "Invalid Credentials" }
        }
        const signInData = response.data.data.signIn
        console.log(signInData)
        const companyResponse = await axios.post("https://customergraph-gd3l4zjdya-uw.a.run.app/graphql", {
            query: `query CompanyName($email: String!) {
                companyName(email: $email)
            }`,
            variables: { email: email },
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        console.log(companyResponse)
        const userData = {
            token: signInData.token,
            fname: signInData.fname,
            lname: signInData.lname,
            email: signInData.email,
            country: signInData.country,
            company: companyResponse.data.data.companyName,
        }
        return userData
    } catch (error) {
        console.log("There was an error in server log in query")
        console.log(error)
    }
}


export const loginSet = (token, user) => {
    axios.defaults.headers.common["Authentication"] = `Bearer ${token}`
    window.localStorage.setItem('token', token)
    window.localStorage.setItem('user', JSON.stringify(user))
}

export const logoutUnset = () => {
    delete axios.defaults.headers.common["Authentication"]
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
}