import { Box, Typography } from "@mui/material"
import { createTheme } from "@mui/system"
import React from "react"

const theme = createTheme()

const BlockData = () => {
    return (
        <Box sx={{
            margin: "15px"
        }}
        >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Block Data</Typography>
            <Typography variant="h8" paragraph>
                No data is available yet. Check back here once you have collected data with the app.
            </Typography>
        </Box>
    )
}

export default BlockData