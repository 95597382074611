import { Box, Typography } from "@mui/material"
import { createTheme } from "@mui/system"
import React from "react"

const theme = createTheme()

const BlockSubscriptions = () => {
    return (
        <Box sx={{
            margin: "15px"
        }}
        >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Subscriptions</Typography>
            <Typography variant="h8" paragraph>
                This feature is not yet available. You will be able to select which blocks you want to
                include in your subscription to our data analysis and collections services.
                <br /><br />
                Expect this feature to be available by July 1st, 2022.
            </Typography>
        </Box>
    )
}

export default BlockSubscriptions