import { Box, Typography } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import React from "react"
import { useRecoilValue } from "recoil"
import { userAuth } from "../../user/api/state"

const theme = createTheme()

const VineyardsOverview = () => {
    const auth = useRecoilValue(userAuth)
    return (
        <Box sx={{
            margin: "15px"
        }}
        >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>Welcome to your console {auth.user.company}!</Typography>
            <Typography variant="h8" paragraph>
                This is the primary location for managing your account and analyzing the data collected in the field. These features are available in the menu to the left.
                <br /><br />
                We will also post announcments here to notify you of new features as they become available.
            </Typography>
        </Box>
    )
}

export default VineyardsOverview