import React, { useState } from 'react';
import { Container, Paper, Typography, Grid, Link, Button, Card, CardMedia, CardActionArea } from '@mui/material';
import { Box } from "@mui/system";
import { useRecoilValue } from "recoil";
import { userAuth } from "../api/state";
import { styled } from "@mui/material/styles"
import ChristmasButton from '../../common/ChristmasButton';


const Welcome = () => {
    const auth = useRecoilValue(userAuth)
    return (
        <div>
            <div style={{ backgroundColor: "black", width: "100vw" }}>
                <Container sx={{ width: "100vw", backgroundColor: "black" }}>
                    <Paper
                        sx={{
                            position: "relative",
                            backgroundColor: "black",
                            color: "white",
                            width: "100%"
                        }}
                    >
                        <Grid container sx={{ width: "100%" }}>
                            <Grid item md={6}>
                                <Box
                                    sx={{
                                        position: "relative",
                                        p: { xs: 3, md: 12 },
                                        pr: { md: 0 },
                                    }}>
                                    <Typography component="h1" variant="h3" gutterBottom sx={{ fontSize: { xs: "28px", sm: "32px", md: "42px" } }}>
                                        Welcome {auth.user.company}!
                                    </Typography>
                                    <Typography variant="h5" paragraph sx={{ fontSize: { xs: "18px", sm: "18px", md: "23px" } }}>
                                        Our console will soon be ready to view your maps and data. In the meantime, the first step is to download the app and mount your iPhone to your ATV or Tractor.
                                    </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: "bold", paddingTop: "25px" }} paragraph>
                                        Step 1: Download App
                                    </Typography>
                                    <Typography variant="h8" paragraph>
                                        Download our app from the app store: <ChristmasButton variant="contained" href="https://apps.apple.com/us/app/redleaf-tracker/id1590913314" sx={{ fontWeight: "bold" }}>Red Leaf Tracker</ChristmasButton>
                                    </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: "bold", paddingTop: "25px" }} paragraph>
                                        Step 2: Mount iPhone
                                    </Typography>
                                    <Typography variant="h8" paragraph>
                                        Mount your iPhone to your ATV or tractor. The recommended solution is to use a DJI OM5 Gimbal.
                                        You can purchase a gimbal here: <Link underline="hover" color="rgb(180, 0, 20)" href="https://store.dji.com/product/dji-om-5">DJI Web Store</Link>, or here: <Link color="rgb(180, 0, 20)" underline="hover" href="https://www.amazon.com/dp/B099ZXD27F/ref=cm_sw_r_tw_dp_Q1H10XZJ2DZW0AHEP4CY">Amazon</Link>.<br /><br />An example mounting system
                                        is shown in the image.
                                        <br /><br />
                                        You will need a system to mount the gimbal to your ATV or tractor. One potential solution is a suction cup mount like the <Link underline="hover" color="rgb(180, 0, 20)" href="https://www.amazon.com/Delkin-Devices-Suction-Camera-DDMOUNT-SUCTION/dp/B0023ZK2TY">Delkin Fat Gecko Dual Suction Camera Mount</Link>.
                                        <br /><br />
                                        If you have a smaller vineyard or want to sample a smaller area, the app can also be used by walking the vineyard while holding the
                                        camera facing the vines. The quality and functionality remains the same as a mounted solution. In this case, a gimbal is not necessary.
                                    </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: "bold", paddingTop: "25px" }} paragraph>
                                        Step 3: Verify Accuracy
                                    </Typography>
                                    <Typography variant="h8" paragraph>
                                        Our app and model are still an early release version that will continue improving over time.
                                        To guarantee that the data collected meets your expectations, we have enabled real-time feedback on what the app is recording.
                                        Check that the app is working on a smaller area with the real-time feedback before continuing with larger data collection. If you notice
                                        issues, feel free to email us at: <Link color="rgb(180, 0, 20)" underline="hover" href="mailto:customersupport@terroirai.com">customersupport@terroirai.com</Link>. Also consider joining our developer program if you would like to help
                                        improve the performance and functionality of the app. To join, send an email to <Link color="rgb(180, 0, 20)" underline="hover" href="mailto:developerprogram@terroirai.com">developerprogram@terroirai.com</Link>.
                                    </Typography>
                                    <Typography variant="h8" sx={{ fontWeight: "bold", paddingTop: "25px" }} paragraph>
                                        Step 4: Collect Data
                                    </Typography>
                                    <Typography variant="h8" paragraph>
                                        Once your iPhone is mounted to you ATV or tractor, log in to the app and follow the prompts to start collecting data.
                                        To record data for mapping, click Start Data Collection and to stop recording, click Stop Data Collection. The data
                                        collected is automatically sent to our database for mapping and statistics when collection is stopped.
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center" }}>
                                <Box sx={{
                                    display: "flex", alignItems: "center", alignContent: "center", justifyContent: "center", width: "100% "
                                }}>
                                    <Card variant="outlined" sx={{ maxWidth: "640px", borderColor: "#21262d", borderWidth: "8px" }}>
                                        <CardMedia
                                            component="img"
                                            height="480"
                                            image={process.env.PUBLIC_URL + '/img/mount.jpg'}
                                            alt="Mounting System"
                                        />
                                    </Card>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container >
            </div></div>
    )
}

export default Welcome