import React, { useRef, useEffect, useState } from "react";
import mapboxGl from "mapbox-gl";
mapboxGl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default // eslint-disable-line

mapboxGl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN

const BlockMap = ({ center, geojson, displayProp, highlightVals = [""], zoom = 13, blockValueCb = (blockValue) => { } }) => {

    const mapContainer = useRef(null)
    let map = useRef(null)

    const [geoHighlighted, setGeoHighlighted] = useState(geojson)

    useEffect(() => {
        let tempGeo = geojson
        let geoFeatures = tempGeo.features
        geoFeatures.forEach(feature => {
            let propertyVal = feature.properties[displayProp]
            if (highlightVals.includes(propertyVal)) {
                feature.properties["highlight"] = "yes"
            } else {
                feature.properties["highlight"] = "no"
            }
        })
        setGeoHighlighted(tempGeo)
    })

    useEffect(() => {
        map = new mapboxGl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/satellite-streets-v11',
            center: center,
            zoom: zoom
        });

        var clickedBlockId = null

        map.on("load", async () => {
            map.addSource("blockmap-source", {
                "type": "geojson",
                "generateId": true,
                "data": geoHighlighted
            })

            map.addLayer({
                "id": "block-map",
                "type": "fill",
                "source": "blockmap-source",
                "layout": {},
                "paint": {
                    "fill-color": [
                        "case",
                        ["boolean", ["feature-state", "click"], false],
                        "#FFFF00",
                        ["==", ["get", "highlight"], "no"],
                        "#0080ff",
                        "#00FF00"
                    ],
                    "fill-opacity": 0.5
                },
            })

            map.addLayer({
                "id": "block-outline",
                "type": "line",
                "source": "blockmap-source",
                "layout": {},
                "paint": {
                    "line-color": "#000",
                    "line-width": 1
                }
            })

            map.on("click", "block-map", (e) => {
                if (e.features.length > 0) {
                    if (clickedBlockId !== null) {
                        map.setFeatureState(
                            { source: 'blockmap-source', id: clickedBlockId },
                            { click: false }
                        )
                    }
                    clickedBlockId = e.features[0].id;
                    map.setFeatureState(
                        { source: 'blockmap-source', id: clickedBlockId },
                        { click: true }
                    )
                }

                let blockValue = e.features[0].properties[displayProp]
                new mapboxGl.Popup()
                    .setLngLat(e.lngLat)
                    .setHTML(e.features[0].properties[displayProp])
                    .addTo(map)

                blockValueCb(blockValue)
            })

            map.addLayer({
                "id": "block-label",
                "type": "symbol",
                "source": "blockmap-source",
                "minzoom": 15,
                "layout": {
                    'text-field': ['get', displayProp],
                    'text-radial-offset': 0.5,
                    'text-justify': 'center',
                    'text-anchor': "center",
                    'icon-image': ['get', 'icon'],
                }
            })
        })
        return () => map.remove()
    });

    return (
        <div style={{ height: "100%", minHeight: "100%", maxHeight: "100%", minWidth: "100%", width: "100%" }}>
            <div style={{ minHeight: "300px", minWidth: "300px", height: "100%", width: "100%" }} ref={mapContainer} className="map-container" />
        </div>
    )
}

export default BlockMap