const phoneCodes = {
    "American Samoa": "1-684",
    "Andorra": "376",
    "Angola": "244",
    "Anguilla": "1-264",
    "Argentina": "54",
    "Armenia": "374",
    "Aruba": "297",
    "Australia": "61",
    "Austria": "43",
    "Barbados": "1-246",
    "Belarus": "375",
    "Belgium": "32",
    "Belize": "501",
    "Bermuda": "1-441",
    "Bhutan": "975",
    "Brazil": "55",
    "Bulgaria": "359",
    "Burundi": "257",
    "Cambodia": "855",
    "Cameroon": "237",
    "Canada": "1",
    "Chile": "56",
    "China": "86",
    "Colombia": "57",
    "Costa Rica": "506",
    "Croatia": "385",
    "Cyprus": "357",
    "Denmark": "45",
    "Dominica": "1-767",
    "Ecuador": "593",
    "El Salvador": "503",
    "Equatorial Guinea": "240",
    "Estonia": "372",
    "Ethiopia": "251",
    "Fiji": "679",
    "Finland": "358",
    "France": "33",
    "French Polynesia": "689",
    "Georgia": "995",
    "Germany": "49",
    "Greece": "30",
    "Greenland": "299",
    "Grenada": "1-473",
    "Guam": "1-671",
    "Guatemala": "502",
    "Guyana": "592",
    "Haiti": "509",
    "Honduras": "504",
    "Hong Kong": "852",
    "Hungary": "36",
    "Iceland": "354",
    "India": "91",
    "Ireland": "353",
    "Isle of Man": "44-1624",
    "Israel": "972",
    "Italy": "39",
    "Japan": "81",
    "Kenya": "254",
    "Latvia": "371",
    "Lebanon": "961",
    "Liberia": "231",
    "Libya": "218",
    "Liechtenstein": "423",
    "Lithuania": "370",
    "Luxembourg": "352",
    "Madagascar": "261",
    "Maldives": "960",
    "Mexico": "52",
    "Monaco": "377",
    "Montenegro": "382",
    "Montserrat": "1-664",
    "Nepal": "977",
    "New Caledonia": "687",
    "New Zealand": "64",
    "Nicaragua": "505",
    "Norway": "47",
    "Panama": "507",
    "Paraguay": "595",
    "Peru": "51",
    "Poland": "48",
    "Portugal": "351",
    "Puerto Rico": "1-787",
    "Romania": "40",
    "Saint Kitts and Nevis": "1-869",
    "Saint Lucia": "1-758",
    "Saint Pierre and Miquelon": "508",
    "Saint Vincent and the Grenadines": "1-784",
    "San Marino": "378",
    "Sao Tome and Principe": "239",
    "Serbia": "381",
    "Seychelles": "248",
    "Sierra Leone": "232",
    "Singapore": "65",
    "Slovakia": "421",
    "Slovenia": "386",
    "Solomon Islands": "677",
    "South Africa": "27",
    "Spain": "34",
    "Svalbard and Jan Mayen": "47",
    "Sweden": "46",
    "Switzerland": "41",
    "Trinidad and Tobago": "1-868",
    "Turkey": "90",
    "Ukraine": "380",
    "United States": "1",
}

export default phoneCodes