import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Container from "@mui/material/Container"
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import * as turf from "@turf/turf"
import BlockMap from "../BlockMap"
import { display } from "@mui/system";
import { useRecoilValue } from "recoil";
import { userAuth } from "../../user/api/state";
import { gql, useMutation } from "@apollo/client"
const theme = createTheme();

const ADD_BLOCKMAP = gql`
    mutation RegisterBlockMap($vineyardName: String!, $blockCode: String!, $geojsonLocal: String!, $geojsonGlobal: String!) {
        registerBlockMap(vineyardName: $vineyardName, blockCode: $blockCode, geojsonLocal: $geojsonLocal, geojsonGlobal: $geojsonGlobal)
    }`

const UploadForm = () => {

    const auth = useRecoilValue(userAuth)

    const [registerBlockMap, { data, loading, uploadError }] = useMutation(ADD_BLOCKMAP)

    const emptyGeoJson = {
        "type": "FeatureCollection",
        "features": [
            {
                "type": "Feature",
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [[
                        [-122.296380, 38.374854],
                    ]]
                },
                "properties": {
                    "Block_Code": "Blank"
                }
            }
        ]
    }
    const zoom = 13
    const [lng, setLng] = useState(-122.28574513418404);
    const [lat, setLat] = useState(38.30138953568207);
    const [blockmap, setBlockMap] = useState(emptyGeoJson)
    const [origBlockmap, setOrigBlockmap] = useState({})
    const [fname, setFname] = useState("No File Selected")
    const [blockIdentifier, setBIdentifier] = useState("")
    const [geoproperties, setProperties] = useState([])
    const [blockCodeError, setCodeError] = useState(false)
    const [vineyardNameError, setVineyardError] = useState(false)
    const [geojsonError, setGeojsonError] = useState(false)
    const token = window.localStorage.getItem('token')

    const axiosSubmitRequest = async (vineyardName, blockCode, geojsonLocal, geojsonGlobal) => {
        const cust_graph_url = "https://cust-graph-dev-gd3l4zjdya-uw.a.run.app/graphql"
        try {
            const response = await axios.post(cust_graph_url, {
                query: `mutation RegisterBlockMap($vineyardName: String!, $blockCode: String!, $geojsonLocal: String!, $geojsonGlobal: String!) {
                    registerBlockMap(vineyardName: $vineyardName, blockCode: $blockCode, geojsonLocal: $geojsonLocal, geojsonGlobal: $geojsonGlobal)
                }`,
                variables: { vineyardName: vineyardName, blockCode: blockCode, geojsonLocal: geojsonLocal, geojsonGlobal: geojsonGlobal },
            },
                {
                    headers: {
                        "Authorization": token,
                        'Content-Type': 'application/json',
                    }
                })
            console.log(response)
        } catch (e) {
            console.error(e)
        }
    }

    const apolloClientRequest = async (vineyardName, blockCode, geojsonLocal, geojsonGlobal) => {
        registerBlockMap({
            variables: {
                vineyardName: vineyardName,
                blockCode: blockCode,
                geojsonLocal: geojsonLocal,
                geojsonGlobal: geojsonGlobal
            },
            onCompleted(data) {
                console.log("completed submission")
                console.log(data)
            }
        })
    }

    const handleSubmitRequest = async (vineyardName, blockCode, globalGeo, localGeo) => {
        const geojsonLocal = JSON.stringify(localGeo)
        const geojsonGlobal = JSON.stringify(globalGeo)
        const submitData = {
            vineyardName: vineyardName,
            blockCode: blockCode,
            geojsonGlobal: geojsonGlobal,
            geojsonLocal: geojsonLocal,
            company: auth.user.company,
            email: auth.user.email,
            token: token
        }
        try {
            axiosSubmitRequest(vineyardName, blockCode, geojsonLocal, geojsonGlobal)
        } catch (error) {
            console.error(error)
        }
        console.log("Submitted Data")
        console.log(submitData)
    }

    const onSubmit = async event => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        const vineyardName = data.get("vineyard-name")
        const submittedBlockCode = data.get("block-name-identifier")
        const vineyardComplete = vineyardName !== ""
        const codeComplete = submittedBlockCode !== ""
        const geojsonLoaded = Object.keys(origBlockmap) !== 0
        setCodeError(!codeComplete)
        setVineyardError(!vineyardComplete)
        setGeojsonError(!geojsonLoaded)
        if (vineyardComplete && codeComplete && geojsonLoaded) {
            handleSubmitRequest(vineyardName, submittedBlockCode, blockmap, origBlockmap)
        }
    }

    const calcCenterPoint = (geo) => {
        let center = turf.centroid(geo)
        let coordinates = center.geometry.coordinates
        setLng(coordinates[0])
        setLat(coordinates[1])
    }

    const extractPropertyLabels = (geo) => {
        let features = geo.features
        let allProperties = new Set()
        features.forEach(feature => {
            let properties = Object.keys(feature.properties)
            properties.forEach((property) => allProperties.add(property))
        })
        let items = []
        allProperties.forEach((property) => {
            items.push(<MenuItem key={property} value={property}>{property}</MenuItem>)
        })
        setProperties(items)
    }

    const fileSelected = async event => {
        let files = event.target.files
        const data = new FormData();
        let numFiles = files.length
        let fname = "No Valid Files Selected"
        for (let i = 0; i < numFiles; i++) {
            let file = files[i]
            data.append("files[]", file)
            let curName = file.name
            let lowName = curName.toLowerCase()
            if (lowName.includes(".zip") || lowName.includes(".shp") || lowName.includes(".geojson")) {
                fname = curName
            }
        }

        let url = "https://rendergeojson-gd3l4zjdya-uw.a.run.app"
        axios.post(url, data, {}).then((res) => {
            let originalGeojson = res.data.original_crs
            let geojson = res.data.global_crs
            calcCenterPoint(geojson)
            setBlockMap(geojson)
            setOrigBlockmap(originalGeojson)
            extractPropertyLabels(geojson)
        }, error => {
            console.error(error)
        })
        setFname(fname)
    }

    const changeBlockIdentifier = (event) => {
        setBIdentifier(event.target.value)
    }

    const blockSelected = (blockValue) => {
        //console.log(blockValue)
    }

    return (
        <Grid
            container
            spacing={0}
            minWidth="300px"
            maxWidth="100%"
            minHeight="100%"
            height="100%"
            sx={{
                display: "flex",
                alignItems: "stretch",
                flexDirection: "row",
                maxHeight: "100%",
                marginBottom: 0,
                marginRight: 0,
                alignItems: "stretch",
                justifyItems: "stretch",
                justifySelf: "stretch",
                width: 1
            }}
        >
            <Grid item xs={12}
                minWidth="100%"
                height="75%"
                sx={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "stretch",
                    justifyItems: "stretch",
                    width: 1,
                    minHeight: "75%",
                    maxHeight: "75%"
                }}
            >
                <BlockMap center={[lng, lat]} geojson={blockmap} displayProp={blockIdentifier} blockValueCb={blockSelected} highlightVals={[""]} />
            </Grid>
            <Grid item xs={12} sx={{
                minWidth: "100%",
                maxWidth: "100%",
                width: "100%"
            }}>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <Grid container spacing={0} justifyContent="center" alignContent="center" alignItems="stretch" width="100%" sx={{ flexDirection: "row" }}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                width: "100%",
                                display: "flex",
                                marginBottom: "15px"
                            }}
                        >
                            <Typography>File Name: {fname}</Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                required
                                id="vineyard-name"
                                name="vineyard-name"
                                label="Vineyard Name"
                                defaultValue=""
                                error={vineyardNameError}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center", flexDirection: "column", width: "100%", display: "flex" }}>
                            <FormControl sx={{ minWidth: 240, marginTop: 2 }}>
                                <InputLabel id="block-name-identifier">
                                    Block Identifier
                                </InputLabel>
                                <Select
                                    labelId="block-name-identifier"
                                    id="block-name-identifier"
                                    name="block-name-identifier"
                                    value={blockIdentifier}
                                    label="Block Identifier"
                                    onChange={changeBlockIdentifier}
                                    error={blockCodeError}
                                >
                                    {geoproperties}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid container spacing={0} sx={{ display: "flex", maxWidth: "350px", marginTop: "20px" }}>
                            <Grid item xs={12} sm={6} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center", flexDirection: "column", width: "100%", display: "flex" }}>
                                <Button variant="contained" component="label" color="secondary">
                                    {" "}
                                    Select File
                                    <input type="file" onChange={fileSelected} hidden multiple />
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={6} sx={{ alignItems: "center", alignContent: "center", justifyContent: "center", flexDirection: "column", width: "100%", display: "flex" }}>
                                <Button type="submit" variant="contained">
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid >
    )
}

export default UploadForm