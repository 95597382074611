import React from 'react';
import {
  RecoilRoot,
} from 'recoil';

import Layout from './components/common/Layout';

function App() {
  return (
    <RecoilRoot>
      <Layout />
    </RecoilRoot>
  );
}

export default App;
