const alpha2 = {
    "American Samoa": "AS",
    "Andorra": "AD",
    "Angola": "AO",
    "Anguilla": "AI",
    "Argentina": "AR",
    "Armenia": "AM",
    "Aruba": "AW",
    "Australia": "AU",
    "Austria": "AT",
    "Barbados": "BB",
    "Belarus": "BY",
    "Belgium": "BE",
    "Belize": "BZ",
    "Bermuda": "BM",
    "Bhutan": "BT",
    "Brazil": "BR",
    "Bulgaria": "BG",
    "Burundi": "BI",
    "Cambodia": "KH",
    "Cameroon": "CM",
    "Canada": "CA",
    "Chile": "CL",
    "China": "CN",
    "Colombia": "CO",
    "Costa Rica": "CR",
    "Croatia": "HR",
    "Cyprus": "CY",
    "Denmark": "DK",
    "Dominica": "DM",
    "Ecuador": "EC",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    "Estonia": "EE",
    "Ethiopia": "ET",
    "Fiji": "FJ",
    "Finland": "FI",
    "France": "FR",
    "French Polynesia": "PF",
    "Georgia": "GE",
    "Germany": "DE",
    "Greece": "GR",
    "Greenland": "GL",
    "Grenada": "GD",
    "Guam": "GU",
    "Guatemala": "GT",
    "Guyana": "GY",
    "Haiti": "HT",
    "Honduras": "HN",
    "Hong Kong": "HK",
    "Hungary": "HU",
    "Iceland": "IS",
    "India": "IN",
    "Ireland": "IE",
    "Isle of Man": "IM",
    "Israel": "IL",
    "Italy": "IT",
    "Japan": "JP",
    "Kenya": "KE",
    "Latvia": "LV",
    "Lebanon": "LB",
    "Liberia": "LR",
    "Libya": "LY",
    "Liechtenstein": "LI",
    "Lithuania": "LT",
    "Luxembourg": "LU",
    "Madagascar": "MG",
    "Maldives": "MV",
    "Mexico": "MX",
    "Monaco": "MC",
    "Montenegro": "ME",
    "Montserrat": "MS",
    "Nepal": "NP",
    "New Caledonia": "NC",
    "New Zealand": "NZ",
    "Nicaragua": "NI",
    "Norway": "NO",
    "Panama": "PA",
    "Paraguay": "PY",
    "Peru": "PE",
    "Poland": "PL",
    "Portugal": "PT",
    "Puerto Rico": "PR",
    "Romania": "RO",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Pierre and Miquelon": "PM",
    "Saint Vincent and the Grenadines": "VC",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Serbia": "RS",
    "Seychelles": "SC",
    "Sierra Leone": "SL",
    "Singapore": "SG",
    "Slovakia": "SK",
    "Slovenia": "SI",
    "Solomon Islands": "SB",
    "South Africa": "ZA",
    "Spain": "ES",
    "Svalbard and Jan Mayen": "SJ",
    "Sweden": "SE",
    "Switzerland": "CH",
    "Trinidad and Tobago": "TT",
    "Turkey": "TR",
    "Ukraine": "UA",
    "United States": "US",
}

export default alpha2