export default {
    pages: {
        home: "/",
    },
    user: {
        login: "/login",
        welcome: "/welcome",
    },
    dashboard: {
        console: "/console"
    },
    shoppingcart: {
        cart: "/shopping/cart",
        success: "/shopping/success",
        fail: "/shopping/error",
    }
}