import React from "react";
import Typography from '@mui/material/Typography';
import { AppBar, Toolbar } from "@mui/material";

const Header = () => {
    return (
        <AppBar position="relative" sx={{ color: "white", background: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
                <img
                    height="50px"
                    src={process.env.PUBLIC_URL + '/img/wlogo.png'}
                    alt="Terroir AI Logo"
                />
                <Typography variant="h6" color="inherit" noWrap>
                    Terroir AI
                </Typography>
            </Toolbar>
        </AppBar>
    )
}

export default Header