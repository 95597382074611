import { Button } from '@mui/material';
import { styled } from "@mui/material/styles"

const ChristmasButton = styled(Button)({
    backgroundColor: "rgb(180, 0, 20)",
    '&:hover': {
        backgroundColor: "green"
    }
})

export default ChristmasButton