import { atom } from 'recoil';
import { loginSet } from './query';

const userAuthDefault = {
    isAuthenticated: false,
    user: null
}

const token = window.localStorage.getItem('token')
if (token && token !== 'undefined' && token !== '') {
    const user = JSON.parse(window.localStorage.getItem("user"))

    if (user) {
        loginSet(token, user)
        userAuthDefault.isAuthenticated = true
        userAuthDefault.user = user
    }
}

export const userAuth = atom({
    key: "userAuth",
    default: userAuthDefault
})