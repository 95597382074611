import { Box, Typography } from "@mui/material"
import { createTheme } from "@mui/material/styles"
import React from "react"
import { useRecoilValue } from "recoil"
import { userAuth } from "../../user/api/state"
import UploadForm from "./UploadForm"

const theme = createTheme()

const AddBlockMap = () => {
    const auth = useRecoilValue(userAuth)
    return (
        <Box sx={{
            margin: "15px",
            display: "flex",
            alignItems: "stretch",
            justifyItems: "stretch",
            width: "100%",
            height: "100%",
        }}
        >
            <UploadForm />
        </Box>
    )
}

export default AddBlockMap