import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import alpha2 from '../../../countryAlpha2';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from '@mui/material';

const theme = createTheme();

const CompanySignup = (props) => {

    const userInfo = props.userInfo
    const country = userInfo.country
    const [missingError, setMissingError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [captchaValue, setCaptchaValue] = useState("")
    const [regError, setRegError] = useState("")

    const PNF = require("google-libphonenumber").PhoneNumberFormat
    const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance()

    const changeCaptcha = (value) => {
        setCaptchaValue(value)
    }

    const onSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget)
        const bname = data.get("bname")
        const baddress = data.get("baddress")
        const city = data.get("city")
        const state = data.get("state")
        const zip = data.get("zip")
        const bphone = data.get("bphone")
        const captchaChecked = captchaValue !== "" && captchaValue !== null
        const dataComplete = bname && baddress && city && zip && bphone
        setMissingError(!dataComplete)
        if (dataComplete) {
            var countryCode = ""
            var phone = ""
            var validPhone = false
            try {
                countryCode = alpha2[country]
                phone = phoneUtil.parseAndKeepRawInput(bphone, countryCode)
                validPhone = phoneUtil.isValidNumberForRegion(phone, countryCode)
                setPhoneError(!validPhone)
            } catch {
                setPhoneError(1)
            }
            if (validPhone) {
                const companyData = {
                    bname,
                    baddress,
                    city,
                    state,
                    zip,
                    bphone: phoneUtil.format(phone, PNF.INTERNATIONAL)
                }
                if (props.submit) {
                    try {
                        props.submit(userInfo, companyData, props.history)
                    } catch (error) {
                        setRegError(error.message)
                    }
                }
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Company Registration
                    </Typography>
                    {missingError &&
                        <Typography sx={{ color: "red" }}>Missing registration information</Typography>
                    }
                    {regError !== "" &&
                        <div>
                            <Typography sx={{ color: "red" }}>{regError}</Typography>
                            <Link href="terroirai.com/login" variant="body2">
                                Go Back
                            </Link></div>
                    }
                    <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    autoComplete="company-name"
                                    name="bname"
                                    required
                                    fullWidth
                                    id="bname"
                                    label="Business Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="baddress"
                                    label="Business Address"
                                    name="baddress"
                                    autoComplete="company-address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="city"
                                    label="City"
                                    name="city"
                                    autoComplete="company-address"
                                />
                            </Grid>
                            {country === "United States" &&
                                <Grid item xs={12} sm={6} >
                                    <TextField
                                        fullWidth
                                        id="state"
                                        label="State"
                                        name="state"
                                        autoComplete="company-address"
                                    />
                                </Grid>}
                            <Grid item xs={12} sm={6} >
                                <TextField
                                    required
                                    fullWidth
                                    id="zip"
                                    label="Zip"
                                    name="zip"
                                    autoComplete="company-address"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="bphone"
                                    label="Business Phone Number"
                                    name="bphone"
                                    autoComplete="phone"
                                    error={phoneError}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default CompanySignup