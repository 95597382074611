import axios from "axios";

export async function registerUser(fname, lname, country, phone, email, password) {
    var creationError = false
    try {
        const response = await axios.post("https://customergraph-gd3l4zjdya-uw.a.run.app/graphql", {
            query: `mutation SignUp($fname: String!, $lname: String!, $country: String!, $phone: String!, $email: String!, $password: String!) {
                signUp(fname: $fname, lname: $lname, country: $country, phone: $phone, email: $email, password: $password) {
                  token
                  email
                  fname
                  lname
                  companyName
                  country
                }
              }`,
            variables: { fname: fname, lname: lname, country: country, phone: phone, email: email, password: password },
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        const responseData = response.data.data
        if (responseData === null) {
            const errormessage = response.data.errors[0].message
            creationError = true
            if (errormessage.search("exists") != -1) {
                throw "User with email: " + email + " already exists."
            } else {
                throw "Couldn't create user account. Contact customer support for help."
            }
        }
        const signUpData = response.data.data.signUp

        const userData = {
            token: signUpData.token,
            fname: signUpData.fname,
            lname: signUpData.lname,
            email: signUpData.email,
            country: signUpData.country,
        }
        return userData
    } catch (error) {
        throw (error)
    }
}

export async function registerCompany(email, businessName, address, zip, businessPhone) {
    try {
        const companyResponse = await axios.post("https://customergraph-gd3l4zjdya-uw.a.run.app/graphql", {
            query: `mutation
                CreateBusiness(
                    $email: String!
                    $businessName: String!
                    $address: String!
                    $zip: String!
                    $businessPhone: String!) {
                    createBusiness(
                        email: $email,
                        businessName: $businessName,
                        address: $address,
                        zip: $zip,
                        businessPhone: $businessPhone)
                        {name}
            }`,
            variables: { email, businessName, address, zip, businessPhone },
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
        if (companyResponse === null) {
            return { error: "Couldn't create company" }
        }
        const companyData = companyResponse.data.data.createBusiness

        return companyData.name
    } catch (error) {
    }
}