import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useSetRecoilState } from 'recoil';
import routes from '../../../routes'
import { userAuth } from '../api/state';
import { login, loginSet } from '../api/query';

const theme = createTheme();

const Login = ({ history, toggleScreen }) => {

    const [email, setEmail] = useState("email")
    const [signError, setSignError] = useState(0)
    const [password, setPassword] = useState("password")
    const setUserAuth = useSetRecoilState(userAuth)
    const onSubmit = async event => {
        event.preventDefault()
        try {
            const data = await login(email, password)
            const userData = {
                fname: data.fname,
                lname: data.lname,
                email: data.email,
                company: data.company,
                country: data.country,
            }
            if (data.email) {
                setUserAuth({
                    isAuthenticated: true,
                    user: userData
                })
                loginSet(data.token, userData)
                history.push(routes.user.welcome)
            } else {
                setSignError(1)
            }
        } catch (error) {
            console.log("Error loging in: " + error.message)
        }
    }

    const switchToReg = () => {
        if (toggleScreen) {
            toggleScreen(1)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    {signError > 0 &&
                        <Typography sx={{ color: "red" }}>Couldn't find login credentials</Typography>
                    }
                    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={event => setEmail(event.target.value)}
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            onChange={event => setPassword(event.target.value)}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="mailto:info@terroirai.com" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2" onClick={switchToReg}>
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Login