import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import routes from '../../../routes';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AppBar, Container, CssBaseline, Toolbar } from "@mui/material";
import Header from "./Header";
import CombinedLogin from "../../user/CombinedLogin";
import Welcome from "../../user/Welcome";
import Home from "../../pages/Home";
import Console from "../../dashboard/Console";



function Copyright() {
    return (
        <div style={{ marginTop: "75px" }}>
            <Typography variant="body2" color="textSecondary" align="center" sx={{ paddingTop: "15px" }}>
                {'Copyright © '}
                <Link color="inherit" href="http://terroirai.com">
                    Terroir AI
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </div>
    )
}

const theme = createTheme();

const Layout = () => {
    return (
        <Router>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header />
                <main>
                    <div width="100%" style={{ height: "90vh", minHeight: "90vh" }}>
                        <div>
                            <Routes>
                                <Route path={routes.dashboard.console} element={<Console />} />
                                <Route path={routes.user.login} element={<CombinedLogin />} />
                                <Route path={routes.user.welcome} element={<Welcome />} />
                                <Route path={routes.pages.home} element={<Home />} />
                            </Routes>
                        </div>
                        <Copyright />
                    </div>
                </main>
            </ThemeProvider>
        </Router>
    )
}

export default Layout