import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { InputLabel, MenuItem, Select } from '@mui/material';
import phoneCodes from '../../../countries';
import alpha2 from '../../../countryAlpha2';

const theme = createTheme();

const Signup = (props) => {
    const [phoneCode, setPhoneCode] = useState("")
    const [countryName, setCountryName] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [missingError, setMissingError] = useState(false)

    const countryKeys = Object.keys(phoneCodes)

    const countryItems = []

    const errorMessage = props.errorMessage

    var emailValidator = require("email-validator")
    const PNF = require("google-libphonenumber").PhoneNumberFormat
    const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance()

    for (const country of countryKeys) {
        countryItems.push(<MenuItem key={country} value={country}>{country}</MenuItem>)
    }

    const changeCountry = (event) => {
        setCountryName(event.target.value)
        setPhoneCode(phoneCodes[event.target.value])
    }

    const onSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget)
        const fname = data.get("firstName")
        const lname = data.get("lastName")
        const country = data.get("selectCountry")
        const countryCode = alpha2[country]
        const phoneNumber = data.get("phone")
        const email = data.get("email").toLowerCase()
        const password = data.get("password")
        const dataComplete = fname && lname && country && phoneNumber && email && password
        setMissingError(!dataComplete)
        if (dataComplete) {
            var phone
            var validPhone
            try {
                phone = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode)
                validPhone = phoneUtil.isValidNumberForRegion(phone, countryCode)
            } catch {
                phone = null
                validPhone = false
            }
            const validEmail = emailValidator.validate(email)
            setEmailError(!validEmail)
            setPhoneError(!validPhone)
            if (validPhone && validEmail) {
                const userData = {
                    fname,
                    lname,
                    country,
                    phone: phoneUtil.format(phone, PNF.INTERNATIONAL),
                    email,
                    password
                }
                props.submit(userData)
            }
        }
    }

    const switchToLogin = () => {
        if (props.toggleScreen) {
            props.toggleScreen(0)
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    {missingError &&
                        <Typography sx={{ color: "red" }}>Missing registration information</Typography>
                    }
                    {errorMessage !== "" &&
                        <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
                    }
                    <Box component="form" noValidate sx={{ mt: 3 }} onSubmit={onSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    autoComplete="given-name"
                                    name="firstName"
                                    required
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    autoFocus
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    autoComplete="family-name"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel id="country-select-label">Select Country *</InputLabel>
                                <Select
                                    required
                                    id="selectCountry"
                                    labelId="country-select-label"
                                    label="Select Country"
                                    name="selectCountry"
                                    autoComplete="country-select"
                                    value={countryName}
                                    fullWidth
                                    onChange={changeCountry}
                                >
                                    {countryItems}
                                </Select>
                            </Grid>
                            {phoneCode != "" &&
                                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                                    <Typography component="h1" variant="h5">+{phoneCode}</Typography>
                                </Grid>
                            }
                            <Grid item xs={10}>
                                <TextField
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone Number"
                                    name="phone"
                                    autoComplete="phone"
                                    error={phoneError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    error={emailError}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link href="#" variant="body2" onClick={switchToLogin}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default Signup