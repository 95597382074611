import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/system";
import { BottomNavigation, BottomNavigationAction, Container, Paper } from "@mui/material";
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Login from '../Login'
import Signup from '../Signup'
import CombinedSignup from '../CombinedSignup';

const CombinedLogin = ({ history }) => {
    const [view, setView] = useState(<CombinedSignup history={history} toggleScreen={toggleScreen} />)
    const [iconValue, setIconValue] = useState(1)


    const switchScreens = (newValue) => {
        if (newValue === 0) {
            setView(<Login history={history} toggleScreen={toggleScreen} />)
            setIconValue(0)
        } else {
            setView(<CombinedSignup history={history} toggleScreen={toggleScreen} />)
            setIconValue(1)
        }
    }

    function toggleScreen(newValue) {
        if (newValue === 0) {
            setView(<Login history={history} toggleScreen={toggleScreen} />)
            setIconValue(0)
        } else {
            setView(<CombinedSignup history={history} toggleScreen={toggleScreen} />)
            setIconValue(1)
        }
    }

    return (
        <Container maxWidth="lg" sx={{ marginBottom: "10px" }}>
            <Box sx={{ display: 'flex', alignContent: "flex-end", width: "100%" }}>
                <Paper elevation={3} sx={{ marginTop: "10px", display: "flex", overflow: "auto", flexDirection: "column", justifyContent: "center", alignItems: "flex- end", width: "100%" }}>
                    {view}
                    <BottomNavigation
                        sx={{ marginTop: "auto", marginLeft: 0, width: "100%" }}
                        showLabels
                        value={iconValue}
                        onChange={(event, newValue) => {
                            switchScreens(newValue);
                        }}
                    >
                        <BottomNavigationAction label="Sign In" icon={<LoginIcon />} />
                        <BottomNavigationAction label="Sign Up" icon={<PersonAddIcon />} />
                    </BottomNavigation>
                </Paper>
            </Box>
        </Container >
    )
}

export default CombinedLogin